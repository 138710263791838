import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../App.css';
import Footer from '../../../Footer';

export default function FeatureWorkshop() {
  return <div className='feature normal-page' style={{backgroundImage: "url('/images/img-4.jpg')", backgroundBlendMode: "multiply", backgroundColor: "rgb(70, 70, 70)"}}>
  <h1>Feature - Workshop</h1>
    <hr></hr>
    <p>The workshop makes Hero Jumper more fun and interesting by adding the possibility for sharing levels with other people or subscribing&playing other levels by other people. That unlocks another level of creativity for everyone. You can share from basic cube to advanced map with 100+ objects to play with.</p>
    </div>;
}
