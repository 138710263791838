import React from 'react';
import '../../../App.css';

export default function HowItWorks() {
  return <div className='howitworks normal-page'>
    <h1>How it works</h1>
    <hr></hr>
    <p>This page is to go over the most important parts of Hero Jumper in detail.</p>
    <h2>Endless Mode</h2>
    <p>Endless mode in Hero Jumper is the place, where you can show your PvE skills. In this mode your goal is to shoot as much enemies as you can by destroying them.
    </p>
    <h2>The Destruction System</h2>
    <p>The destruction system of Hero Jumper is the core mechanic of the game. It works by creating an invisible <a href="https://en.wikipedia.org/wiki/Voronoi_diagram#:~:text=In%20mathematics%2C%20a%20Voronoi%20diagram,%2C%20sites%2C%20or%20generators).">Voronoi</a> diagram and then cutting the object model at the position of the intersection points. Sub-objects are then created with the model of any of the broken parts.</p>
    <h2>Level Editor</h2>
    <p>The level editor of the game adds the possibility of creating whatever you want. It is also used to publish levels to <a href='https://steamcommunity.com//workshop/browse?appid=2153600'>Steam Workshop</a>. Create the best maps to smash and destroy.
    <br /><br />
    The Level Editor also supports modding with Lua. With scripting, you can change the logic of the game and how it works.</p>
    
    <h3 style={{marginTop: "50px"}}>See also</h3>
    
    <ul>
        <li>Voronoi Diagram Explanation - <a href='https://en.wikipedia.org/wiki/Voronoi_diagram#:~:text=In%20mathematics%2C%20a%20Voronoi%20diagram,%2C%20sites%2C%20or%20generators).'>Wikipedia Page</a></li>
        <li>Hero Jumper Official Workshop - <a href='https://steamcommunity.com/app/2153600/workshop/'>Steam Workshop</a></li>
        <li>Level Editor Documentation - <a href='https://docs.playherojumper.com'>Hero Jumper Docs</a></li>
    </ul>

    { /*<iframe src='https://en.wikipedia.org/wiki/Voronoi_diagram#:~:text=In%20mathematics%2C%20a%20Voronoi%20diagram,%2C%20sites%2C%20or%20generators).' width={window.innerWidth - 20 + "px"} height="1000px"></iframe>*/}
    </div>;
}
