import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/pages/About';
import Screenshots from './components/pages/Screenshots';
import Trailer from './components/pages/Trailer';
import HowItWorks from './components/pages/footer/HowItWorks';
import FAQ from './components/pages/footer/FAQ';
import Credits from './components/pages/Credits';
import { AnimatePresence } from 'framer-motion';
import FeatureDestruction from './components/pages/cards/features/FeatureDestruction';
import FeatureWorkshop from './components/pages/cards/features/FeatureWorkshop';
import Contact from './components/pages/Contact';
import FeatureLevelEditor from './components/pages/cards/features/FeatureLevelEditor';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <AnimatePresence>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about' component={About} />
            <Route path='/screenshots' component={Screenshots} />
            <Route path='/trailer' component={Trailer} />
            <Route path='/how-it-works' component={HowItWorks} />
            <Route path='/faq' component={FAQ} />
            <Route path='/credits' component={Credits} />
            <Route path='/contact' component={Contact} />

            <Route path='/features/destruction' component={FeatureDestruction} />
            <Route path='/features/workshop' component={FeatureWorkshop} />
            <Route path='/features/lvl-editor' component={FeatureLevelEditor} />
          </Switch>
        </AnimatePresence>
      </Router>
    </>
  );
}

export default App;
