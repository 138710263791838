import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About</h2>
            <Link to='/how-it-works'>How it works</Link>
            <Link to='/faq'>FAQ</Link>
            <Link to='/credits'>Credits</Link>
            <a href='https://retroblog.playherojumper.com'>About me</a>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href='https://www.youtube.com/@herojumpergame'>Youtube</a>
            <a href='https://twitter.com/herojumpergame'>Twitter</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img style={{ width: "260px" }} src='/images/logo.png' alt='navbar-logo'></img>
            </Link>
          </div>
          <small class='website-rights'>Hero Jumper &copy; {new Date().getFullYear()}</small>
          <div class='social-icons'>
            <a
              class='social-icon-link email'
              href='mailto: herojumperservices@gmail.com'
              target='_blank'
              aria-label='Email'
            >
              <i class='fa fa-envelope' />
            </a>
            <a
              class='social-icon-link discord'
              href='https://discord.gg/mnnMJAZFp6'
              target='_blank'
              aria-label='Discord'
            >
              <i class='fab fa-discord' />
            </a>
            <a
              class='social-icon-link youtube'
              href='https://www.youtube.com/@herojumpergame'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </a>
            <a
              class='social-icon-link twitter'
              href='https://twitter.com/herojumpergame'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
