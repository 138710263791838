import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import Carousel from 'react-bootstrap/Carousel';

function Cards() {
  return (
    <div className='cards' id='cards'>
      <h1>Features In Hero Jumper</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-9.jpg'
              text='Procedural Destruction'
              label='Important'
              path='/features/destruction'
              link="features/destruction"
              />
            <CardItem
              src='images/img-3.jpg'
              text='Workshop Support'
              label='Luxury'
              path='/features/workshop'
              link="features/workshop"
              />
            <CardItem
              src='images/img-5.jpg'
              text='Level Editor'
              label='Luxury'
              path='/features/lvl-editor'
              link="features/lvl-editor"
              />
          </ul>
        </div>
      </div>
      
      <h1>Discord Server</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <iframe src="https://discord.com/widget?id=1043983238345007215&theme=dark" width="350" height="300" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          </ul>

          <p>You can also get help in my Discord Server</p>
        </div>
      </div>

      <h1>Screenshots</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100" src="/images/screenshots/img-1.png" alt="screenshot"></img>
              </Carousel.Item>

              <Carousel.Item>
                <img className="d-block w-100" src="/images/screenshots/img-2.png" alt="screenshot"></img>
              </Carousel.Item>

              <Carousel.Item>
                <img className="d-block w-100" src="/images/screenshots/img-3.png" alt="screenshot"></img>
              </Carousel.Item>

              <Carousel.Item>
                <img className="d-block w-100" src="/images/screenshots/img-4.png" alt="screenshot"></img>
              </Carousel.Item>

              <Carousel.Item>
                <img className="d-block w-100" src="/images/screenshots/img-5.png" alt="screenshot"></img>
              </Carousel.Item>
            </Carousel>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
