import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { LinkButton } from './LinkButton';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video className="background-video" src='/videos/video-1.mov' autoPlay loop muted />
      <img style={{ width: "calc(100% - 300px)" }} src='/images/logo.png'></img>
      <p>Sandbox Destruction Game</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          clickLink='/trailer'
          isHeroLink='true'
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button>
        <LinkButton
          className='btns'
          buttonStyle='btn--success'
          buttonSize='btn--large'
          clickLink='https://store.steampowered.com/app/2169160/Hero_Jumper_Demo/'
          isHeroLink='true'
        >
          FREE DEMO <i className='fa fa-gamepad' />
        </LinkButton>
      </div>

      <iframe style={{marginTop: "40px"}} src="https://store.steampowered.com/widget/2153600/?t=Buy%20Hero%20Jumper%20on%20Steam%20to%20start%20your%20destructiable%20adventure!" frameborder="0" width="646" height="190"></iframe>
    </div>
  );
}

export default HeroSection;
