import React from 'react';
import '../../App.css';
import VideoPlayer from "react-video-js-player"

export default function Trailer() {
  return (
    <>
      <h1 className='trailer'>Watch the trailer</h1>

      <video width={window.innerWidth + "px"} controls autoPlay>
        <source src='/videos/video-1.mov'></source>
      </video>
    </>
  );
}
