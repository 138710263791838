import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_gr89ard', 'template_c2v8pcs', form.current, 'p4ueC2G139ue4xI6Y')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className='contact normal-page main-page'>
            <h1>Contact</h1>
            <hr></hr>
            <p>If you want to ask me something, you can use this form to send me email directly to Hero Jumper email. Then I'll will respond to the email you entered. Please do not use this form for jokes or any other inappropriate messages. You can send:
                
                <ul>
                    <li>Questions that are not included in <a href='/faq'>FAQ</a></li>
                    <li>Ideas for Hero Jumper</li>
                    <li>Other related stuff.</li>
                </ul>
            </p>

            <form ref={form} onSubmit={sendEmail}>
                <div className='contact-inputs'>
                    <label>Name<small className='required-asterik'>*</small></label>
                    <input type="text" name="user_name" required />
                    <label>Email<small className='required-asterik'>*</small></label>
                    <input type="email" name="user_email" required />
                </div>

                <label>Message</label>
                <textarea name="message" />
                <input className='btn-submit' type="submit" value="Send" />
            </form>

            <div style={{ margin: "20px" }}>
                <p>You can also get help in our Discord Server</p>
                <iframe src="https://discord.com/widget?id=1043983238345007215&theme=dark" width="350" height="300" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
        </div>
    )
}



export default Contact