import React from 'react';
import '../../App.css';

export default function Screenshots() {
  return <div className='screenshots'>
  <h1>Screenshots</h1>
  <hr></hr>
  <p>This is the gallery of Hero Jumper. Here you can preview various</p>
  </div>;
}
