import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../App.css';
import Footer from '../../../Footer';

export default function FeatureDestruction() {
  return <div className='feature normal-page' style={{backgroundImage: "url('/images/img-1.jpg')", backgroundBlendMode: "multiply", backgroundColor: "gray", backgroundSize: "auto"}}>
  <h1>Feature - Procedural Destruction</h1>
    <hr></hr>
    <p>The destruction system of Hero Jumper is the core mechanic of the game. It works by creating an invisible <a href="https://en.wikipedia.org/wiki/Voronoi_diagram#:~:text=In%20mathematics%2C%20a%20Voronoi%20diagram,%2C%20sites%2C%20or%20generators).">Voronoi</a> diagram and then cutting the object model at the position of the intersection points. Sub-objects are then created with the model of any of the broken parts.</p>
    </div>;
}
