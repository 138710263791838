import React from 'react';
import '../../App.css';

export default function Credits() {
  return <div className='credits normal-page'>
  <h1>Credits</h1>
  <hr></hr>
  <p>These people&tools helped making Hero Jumper be better.</p>
  <ol>
    <li><a href='https://unity.com/'>Unity Engine</a> - Rendering, Game Engine</li>
    <li><a href='https://g.co/kgs/Xoze7m'>Retr0A</a> - Main Idea, Game Developer</li>
    <li><a href='/credits'>Yoan Georgiev</a> - Original Game Soundtrack.</li>
  </ol>
  </div>;
}
