import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../App.css';
import Footer from '../../../Footer';

export default function FeatureLevelEditor() {
  return <div className='feature normal-page' style={{backgroundImage: "url('/images/img-5.jpg')", backgroundBlendMode: "multiply", backgroundColor: "rgb(70, 70, 70)"}}>
  <h1>Feature - Level Editor</h1>
    <hr></hr>
    <p>The Level Editor of the game adds the possibility of creating whatever you want. It is also used to publish levels to <a href='https://steamcommunity.com//workshop/browse?appid=2153600'>Steam Workshop</a>.
    
    The Level Editor also supports modding with Lua. With scripting, you can change the logic of the game and how it works.</p>
    
    <h3 style={{marginTop: "50px"}}>See also</h3>
    
    <ul>
        <li>Level Editor Documentation - <a href='https://docs.playherojumper.com'>Hero Jumper Docs</a></li>
    </ul>
    </div>;
}
