import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function About() {
  return <div className='about'>
    <h1>About</h1>
    <hr></hr>
    <p>Hero Jumper is sandbox first person shooter game, where you break every single object in the game to its smallest parts. Destroy enemies that try to kill you. Another game mode is the sandbox mode where you can play downloaded maps from the Steam Workshop(You can find more <a href='https://docs.playherojumper.com'>here</a>).</p>
    </div>;
}
