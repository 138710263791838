import React from 'react';
import '../../../App.css';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';

export default function FAQ() {
  return <div className='howitworks normal-page'>
    <h1>FAQ</h1>
    <hr></hr>
    <p>These are some of frequently asked questions:</p>

    <Accordion defaultActiveKey="-1" style={{width: "calc(100% - 200px)"}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is the minimum requirements for PC to run Hero Jumper?</Accordion.Header>
        <Accordion.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Subject</th>
                <th>Minimum</th>
                <th>Recommended</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Operating system version</td>
                <td>Windows 7 (SP1+)</td>
                <td>Windows 11 x64</td>
              </tr>
              <tr>
                <td>CPU	</td>
                <td>X32 architecture with SSE2 instruction set support</td>
                <td>X64 architecture with SSE2 instruction set support</td>
              </tr>
              <tr>
                <td>Graphics API</td>
                <td>DX10</td>
                <td>DX12</td>
              </tr>
              <tr>
                <td>Additional requirements</td>
                <td colSpan={2}>Hardware vendor officially supported drivers</td>
              </tr>
            </tbody>
          </Table>
          
          <p>If you run Hero Jumper on minimum requierments the application might crash, lag or other application will not work properly.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Is Hero Jumper Avaliable for Windows/Linux/MacOS/Android?</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li>
              Windows
              <ul>
                <li>Available, Fully Supported</li>
              </ul>
            </li>
            <li>
              Linux
              <ul>
                <li>Maybe in future, but not sure</li>
              </ul>
            </li>
            <li>
              MacOS
              <ul>
                <li>Unlikely, but probably in future</li>
              </ul>
            </li>
            <li>
              Android
              <ul>
                <li>For Hero Jumper it is impossible to have Android support, because the destruction system and the controls of the game cannot be fit in smartphone screen.</li>
              </ul>
            </li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Is Hero Jumper avaliable in other languages?</Accordion.Header>
        <Accordion.Body>
          <a>Hero Jumper is currently only available in English. It might support other localization at a later stage.</a>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What game engine is Hero Jumper using?</Accordion.Header>
        <Accordion.Body>
          <a>Unity Engine, but with many plugins.</a>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <p>If you have any questions feel free to ask on <a href = "mailto: herojumperservices@gmail.com">herojumperservices@gmail.com</a>.</p>
  </div>;
}
